import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.css';
import 'primevue/resources/themes/arya-blue/theme.css'
import ToastService from 'primevue/toastservice'

let app = createApp(App);
app.use(PrimeVue);
app.use(ToastService);
app.mount('#app');

