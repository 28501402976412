<template>

  <div class="bgimg w3-display-container w3-animate-opacity w3-text-white">
    <div class="w3-display-middle">

       <div class="w3-center">
        <img
          src="./assets/logo.png"
          class="w3-wide"
          style="width:50%"
        />
      </div>

      <h1 class="w3-animate-top center-text">Now LIVE on </h1>
      <a href="https://www.kickstarter.com/projects/862170366/starframe/description">
        <div class="w3-center w3-button" >
          <img
            src="./assets/kickstarter.png"
            class="w3-wide"
            style="width:50%"
          />
        </div>
      </a>

      <div class="w3-center email">
        <span>
          <input-text class="email-address" id="emailAddress" type="text" v-model="emailAddress" placeholder="Your email address"/>
          <Button label="Subscribe to Newsletter" class="p-button-raised" @click="subscribe" :disabled="!isValidEmail()"/>
        </span>   
      </div>

      <div class="w3-center">
        <img
          href="http://exodusgamesusa.com"
          src="./assets/ExodusGamesLogo.png"
          class="w3-bar-item w3-wide"
          style="width:50%"
        />
      </div>

      <div class="w3-center w3-text-white w3-large" >
        <a href="https://www.facebook.com/exodusgames"><i class="fab fa-facebook w3-hover-opacity"></i></a> &nbsp;
        <a href="https://www.instagram.com/exodusgamescompany/"><i class="fab fa-instagram w3-hover-opacity"></i></a> &nbsp;
        <a href="https://www.youtube.com/channel/UCEpXJH_S8xwmruuq8kYmXhA"><i class="fab fa-youtube w3-hover-opacity"></i></a>
      </div>

    </div>
    <Toast Position="bottom-center"/>
    <Dialog header="Thank you for Subscribing!" v-model:visible="showDialog" :modal="true" :closeOnEscape="true" :closable="true" :dismissableMask="true">
      {{dialogContent}}
      <template #footer>
          <Button label="Close" icon="pi pi-times" @click="closeDialog" class="p-button-text" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import axios from "axios";
import {useToast} from 'primevue/usetoast';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';

export default {
  name: "App",
  components: { InputText, Button, Toast, Dialog },
  data() {
    return {
      emailAddress: '',
      toast: null,
      showDialog: false,
      dialogContent: "",
    }
  },
  mounted() {
    this.toast = useToast(); 
    console.dir(this.toast);
  },
  methods: {
    isValidEmail() {
      let result = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.emailAddress);

      return result;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async subscribe() {

      try {
         let subscriber = JSON.stringify(`${this.emailAddress}`);

         console.log("Subscriber",subscriber);

         await axios.post("/api/subscribe",
                          subscriber,
                          {
                            headers: { 'Content-Type': 'application/json' }
                          });
          this.dialogContent = "We will be sending news updates to your inbox soon!";
          this.showDialog = true;

      } catch(err) {
        this.toast.add({ severity: 'error', summary: `Unable to subscribe: ${err.message}`, closable: true, life: 5000});
        console.log("tried to send the toast");
      }
    }
  },
  computed: {
    daysLeft() {
      return Math.floor(
        (Date.parse("2021-05-25T12:00:00.000") - new Date()) / 86400000
      );
    }
  }
};
</script>

<style>
@import url(https://www.w3schools.com/w3css/4/w3.css);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import "./assets/fontawesome-free-5.15.3-web/css/fontawesome.css";
@import "./assets/fontawesome-free-5.15.3-web/css/brands.css";

body,
h1 {
  font-family: "Raleway", sans-serif;
}
body,
html {
  height: 100%;
}

h1 {
  font-family: "Raleway", sans-serif;
}
#app {
  height: 100%;
}

.bgimg {
  background-image: url("assets/starframe2.png");
  min-height: 100%;
  background-position: center;
  background-size: cover;
}

.email {
  margin: 25px;

}
.center-text {
  text-align: center;
  opacity: 1;
  text-shadow: 2px 2px #000000;
}
.important-text {
  font-weight: bold;
  opacity: 1;
  text-shadow: 2px 2px #000000;
}
.email-address {
  width: 300px;
}

.kickstarter{
  color: #05ce78;
  opacity: 1;
}

.transbox {
  background-color: rgb(0, 0, 0);
  opacity: 0.7;
}
</style>
